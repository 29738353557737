<template>
  <span>
    <h3 id="titulo" class="text-center">Relatório de Reserva Cartão</h3>
    <div>
      <table id="tabela">
        <thead>
          <tr>
            <th>Consignatária</th>
            <th>Matrícula</th>
            <th>CPF</th>
            <th>Servidor</th>
            <th>Rubrica</th>
            <th>Entidade</th>
            <th>Data do Cadastro</th>
            <th>Situação</th>
            <th>Data de Cancelamento</th>
            <th class="text-right">Valor da Reserva</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="data in relatorioStore.response" :key="data.id">
            <td>{{ data.consignatariaRazaoSocial }}</td>
            <td>{{ data.matricula }}</td>
            <td>{{ data.cpf | mascaraCpf }}</td>
            <td>{{ data.servidor }}</td>
            <td>{{ data.rubrica }}</td>
            <td>{{ data.nomeEntidade }}</td>
            <td>{{ data.dataCadastro | formatarData }}</td>
            <td>{{ data.statusReservaCartao.descricao }}</td>
            <td class="text-center">
              {{ data.dataCancelamento | formatarData }}
            </td>
            <td class="text-right">
              <span v-if="relatorioStore.contentType == 'PDF'">
                {{ data.valorReserva | formatarValor }}
              </span>
              <span v-else>
                {{ data.valorReserva | formatarValorSemCifrao }}
              </span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="8" class="text-right">Total</td>
            <td colspan="3" class="formatarValor">
              {{ totalValorReserva() | formatarValor }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </span>
</template>
<script>
import { storeRelatorio } from '@/stores/relatorio'

export default {
  setup() {
    const relatorioStore = storeRelatorio()

    return { relatorioStore }
  },

  methods: {
    totalValorReserva() {
      let total = 0
      this.relatorioStore.response.map((item) => {
        total += item.valorReserva
      })
      return this.formatarValor(total)
    },

    formatarValor(value) {
      return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
#titulo {
  font-weight: bold;
}

.formatarValor {
  text-align: right !important;
}

#tabela {
  border-collapse: collapse;
  width: 100%;
  font-size: 0.675rem;
}
#tabela th {
  background-color: #333;
  color: #fff;
  padding: 4%;
  text-align: left;
}

#tabela td {
  padding: 4%;
  border-bottom: 1px solid #ddd;
}

#tabela tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

#tabela tfoot {
  background-color: #666666;
  color: #fff;
}

@media print {
  #titulo {
    font-weight: bold;
  }

  .formatarValor {
    text-align: right !important;
  }

  #tabela {
    border-collapse: collapse;
    width: 100%;
    font-size: 0.675rem;
  }
  #tabela th {
    background-color: #333;
    color: #fff;
    padding: 4%;
    text-align: left;
  }

  #tabela td {
    padding: 4%;
    border-bottom: 1px solid #ddd;
  }

  #tabela tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #tabela tfoot {
    background-color: #666666;
    color: #fff;
  }
}
</style>
